<template>
  <div class="custom-table gt-nexus-group-second">
    <table>
      <thead>
        <tr>
          <th v-for="column in tableColumns" :key="`thead ${column.text}`">
            <span>{{ column.text }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="(column, columnIndex) in tableColumns" :key="`td ${column.text} ${columnIndex}`">
            <template v-if="column.excludeMatching !== true">
              {{ item[column.value] }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      tableColumns: [
        //text : thead 표시
        //value : field Name
        //excludeMatching : 테이블 표시시 별도 컨트롤 필요 여부 (false일 경우 value랑 매칭하여 표기), default: false
        { text: "OriginCountry", value: "originCountry" },
        { text: "DestinationCountry", value: "dc" },
        { text: "OriginCity", value: "originCity" },
        { text: "ShipmentMethodCode", value: "shipmentMethodCode" },
        { text: "DeptCode", value: "deptCode" },
      ],
    };
  },
};
</script>

<style></style>
