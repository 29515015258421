<template>
  <v-dialog
    v-model="dialogShow"
    min-width="400px"
    max-width="1200px"
    content-class="company gt-nexus-single customEditModal">
    <v-card class="pa-5">
      <h1>{{ isDPO ? "DPO" : "Single" }} :: {{ isDPO ? item.dcpo : item.id }}</h1>

      <div class="mt-10 content">
        <single-table-first :item="item" />
        <single-table-second :item="item" />
        <single-table-third
          :items="item.poSingleDetailDtoList"
          :id="item.id"
          :dcpo="item.dcpo"
          :isDPO="isDPO"
          class="mt-4" />
        <single-table-fourth :items="item.poCompanyDtoList" class="mt-4" />
      </div>

      <div class="actions d-flex justify-center">
        <v-btn :elevation="0" color="secondary" @click="dialogShow = false">CLOSE</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { hasValue, numberWithComma } from "@/utils/util";
import SingleTableFirst from "./SingleTableFirst.vue";
import SingleTableSecond from "./SingleTableSecond.vue";
import SingleTableThird from "./SingleTableThird.vue";
import SingleTableFourth from "./SingleTableFourth.vue";
export default {
  components: { SingleTableFirst, SingleTableSecond, SingleTableThird, SingleTableFourth },
  props: ["show", "item", "isDPO"],
  data() {
    return {};
  },
  methods: {
    hasValue,
    numberWithComma,
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
};
</script>

<style></style>
