<template>
  <div class="custom-table gt-nexus-style-detail">
    <!-- <div class="d-flex align-center">
      <span class="font-bold" style="line-height: 1; font-size: 15px; padding-bottom: 7px"> PO Detail Summary </span>
      <v-btn color="secondary" x-small icon style="margin: 0; margin-left: 4px; align-self: center" @click="copy">
        <v-icon> mdi-content-copy </v-icon>
      </v-btn>
    </div> -->
    <table id="gtNexusStyleDetailTable">
      <thead>
        <tr>
          <th v-for="column in tableColumns" :key="`thead ${column.text}`">
            <span>{{ column.text }}</span>
          </th>
          <th v-for="sizeKey in sizeKeys" :key="`thead ${sizeKey}`">
            <span> {{ sizeKey }} </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, itemIndex) in items" :key="`tr ${itemIndex}`">
          <td v-for="(column, columnIndex) in tableColumns" :key="`td column ${column.text} ${columnIndex}`">
            <template v-if="column.excludeMatching !== true">
              {{ item[column.value] }}
            </template>

            <span
              v-else-if="groupColumn.includes(column.value)"
              :id="`${column.value}${itemIndex}`"
              :name="`${column.value}`"
              :data-key="getKey(item, column)"
              :data-index="itemIndex">
              {{ item[column.value] }}
            </span>

            <!-- pricePerUnit/total -->
            <template v-else-if="column.value === 'pricePerUnit' || column.value === 'total'">
              {{ numberWithComma(item[column.value]) }}
            </template>
          </td>

          <td v-for="sizeKey in sizeKeys" :key="`td size ${sizeKey}`">
            <span> {{ numberWithComma(item[sizeKey]) }} </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { numberWithComma, hasValue } from "@/utils/util";
import gtNexusStyleMixin from "@/mixins/gtNexusStyleMixin";

export default {
  props: ["items", "sizeKeys"],
  mixins: [gtNexusStyleMixin],
  data() {
    return {
      tableColumns: [
        //text : thead 표시
        //value : field Name
        //excludeMatching : 테이블 표시시 별도 컨트롤 필요 여부 (false일 경우 value랑 매칭하여 표기), default: false
        { text: "Market", value: "market", excludeMatching: true, grouping: true },
        { text: "BrandPO#", value: "poNo", excludeMatching: true, grouping: true },
        { text: "DCPO#", value: "dpoNo", excludeMatching: true, grouping: true },
        { text: "ProgramType", value: "programType", excludeMatching: true, grouping: true },
        { text: "LatestDate", value: "latestDate", excludeMatching: true, grouping: true },
        { text: "CNXL", value: "cnxl" },
        { text: "InDC", value: "inDC" },
        { text: "InStore", value: "inStore" },
        { text: "GarmentColor", value: "color" },
        { text: "PricePerUnit", value: "pricePerUnit", excludeMatching: true },
        { text: "Total", value: "total", excludeMatching: true },
      ],
    };
  },
  methods: {
    getKey(item, { value }) {
      if (!item || !value) return;

      switch (value) {
        case "market":
          return `${item.market}`;
        case "poNo":
          return `${item.market}|${item.poNo}`;
        case "dpoNo":
          return `${item.market}|${item.poNo}|${item.dpoNo}`;
        case "programType":
          return `${item.market}|${item.poNo}|${item.dpoNo}|${item.programType}`;
        case "latestDate":
          return `${item.market}|${item.poNo}|${item.dpoNo}|${item.programType}|${item.latestDate}`;
        default:
          return "";
      }
    },
    numberWithComma,
    hasValue,
  },
  computed: {
    groupColumn() {
      return this.tableColumns.filter(c => c.grouping).map(c => c.value);
    },
  },
  updated() {
    this.$nextTick(function () {
      this.groupColumn.forEach(column => {
        this.tableGroupping(document.querySelector("#gtNexusStyleDetailTable"), column);
      });
    });
  },
};
</script>

<style></style>
