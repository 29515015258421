<template>
  <v-dialog
    v-model="dialogShow"
    min-width="400px"
    max-width="1200px"
    content-class="company gt-nexus-style customEditModal">
    <v-card>
      <h1>Style :: {{ styleNo }}</h1>
      <div class="modal-content-container">
        <div class="mt-10 content">
          <div class="mb-2" v-if="issuedDpoList.length > 0">
            <span class="font-error font-bold">
              *Below POs* are awaiting approval now. Not reflected in statistics..
            </span>
            <br />
            <span>
              <template v-for="(dpo, dpoIndex) in issuedDpoList">
                {{ `${dpo}${dpoIndex === issuedDpoList.length - 1 ? "" : ", "}` }}
              </template>
            </span>
          </div>

          <styleDetailSummary :items="detailSummaryList" :sizeKeys="sizeList" :key="styleNo + 1" />
          <styleMarketSummary :items="marketSummaryList" :sizeKeys="sizeList" :key="styleNo + 2" class="mt-4" />
          <styleColorSummary :items="colorSummaryList" :sizeKeys="sizeList" :key="styleNo + 3" class="mt-4" />
          <styleWeeklySummary :items="weeklySummaryList" :sizeKeys="sizeList" :key="styleNo + 4" class="mt-4" />
        </div>

        <div class="actions d-flex justify-center">
          <v-btn :elevation="0" color="secondary" @click="dialogShow = false">CLOSE</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
4

<script>
import codeHandler from "@/utils/codeHandler";
import { hasValue, numberWithComma } from "@/utils/util";
import StyleDetailSummary from "./StyleDetailSummary.vue";
import StyleMarketSummary from "./StyleMarketSummary.vue";
import StyleColorSummary from "./StyleColorSummary.vue";
import StyleWeeklySummary from "./StyleWeeklySummary.vue";

export default {
  components: { StyleDetailSummary, StyleMarketSummary, StyleColorSummary, StyleWeeklySummary },
  // components: { StyleDetailSummary },
  props: ["show", "styleNo"],
  data() {
    return {
      styleInfoList: [],
    };
  },
  methods: {
    getList() {
      this.$emit("changeLoading", true);

      this.$http
        .get("gt-nexus/buyer-info/style-no", {
          data: {
            styleNo: this.styleNo,
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            if (hasValue(res.data.data)) {
              const { poWeeklyList, poWeeklySummaryList, poWeeklySummaryDetailList } = res.data.data;
              const weeklyInfoList = [];

              if (Array.isArray(poWeeklyList)) {
                poWeeklyList.forEach(weekly => {
                  const summary = poWeeklySummaryList.filter(weeklySummary => weeklySummary.weekly === weekly);
                  const detail = poWeeklySummaryDetailList.filter(weeklyDetail => weeklyDetail.weekly === weekly);

                  const dpoNo = summary.map(w => `${w.dpoNo} : ${numberWithComma(w.total)}`).join("<br />");

                  detail.forEach(w => {
                    weeklyInfoList.push({
                      weekly,
                      dpoNo,
                      ...w,
                    });
                  });
                });
              }

              res.data.data.weeklyInfoList = weeklyInfoList;

              this.styleInfoList = res.data.data;
            }
          } else codeHandler(res.data);
        })
        .finally(() => this.$emit("changeLoading", false));
    },
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    sizeList() {
      return this.styleInfoList?.sizeList || [];
    },
    detailSummaryList() {
      return this.styleInfoList?.poDetailSummaryList || [];
    },
    marketSummaryList() {
      return this.styleInfoList?.poMarketSummaryList || [];
    },
    colorSummaryList() {
      return this.styleInfoList?.poColorSummaryList || [];
    },
    weeklySummaryList() {
      return this.styleInfoList?.weeklyInfoList || [];
    },
    issuedDpoList() {
      return this.styleInfoList?.poIssuedDpoList || [];
    },
  },
  watch: {
    styleNo(newVal) {
      if (!hasValue(newVal)) return;
      this.getList();
    },
  },
};
</script>

<style lang="scss"></style>
