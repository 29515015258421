<template>
  <div class="custom-table gt-nexus-single-fourth">
    <table>
      <thead>
        <tr>
          <th>Role</th>
          <th>Address</th>
          <th>Role</th>
          <th>Address</th>
        </tr>
      </thead>
      <tbody v-if="items && items.length > 0" class="text-start">
        <tr v-for="firstNum in 2" :key="`tr ${firstNum}`">
          <template v-for="secondNum in 2">
            <td :key="`td1 ${getIndex(firstNum, secondNum)}`" class="text-center">
              {{ items[getIndex(firstNum, secondNum)].role }}
            </td>
            <td :key="`td2 ${getIndex(firstNum, secondNum)}`" class="pa-1">
              <span>
                {{
                  `${items[getIndex(firstNum, secondNum)].name} ( ${items[getIndex(firstNum, secondNum)].department} )`
                }}
              </span>
              <br />
              <span>{{ items[getIndex(firstNum, secondNum)].addressLine1 }}</span> <br />
              <span>{{ items[getIndex(firstNum, secondNum)].addressLine2 }}</span> <br />
              <span>
                {{
                  `
                  ${items[getIndex(firstNum, secondNum)].city || ""} 
                  ${items[getIndex(firstNum, secondNum)].stateOrProvince || ""} 
                  ${items[getIndex(firstNum, secondNum)].postalCodeNumber || ""}
                  `
                }}
              </span>
              <br />
              <span>{{ items[getIndex(firstNum, secondNum)].countryCode }}</span>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["items"],
  methods: {
    getIndex(x, y) {
      return (x - 1) * 2 + (y - 1);
    },
  },
};
</script>

<style></style>
