<template>
  <div class="custom-table gt-nexus-group-third">
    <table>
      <thead>
        <tr>
          <th v-for="column in tableColumns" :key="`thead ${column.text}`">
            <span>{{ column.text }}</span>
          </th>
        </tr>
      </thead>
      <tbody v-if="items && items.length > 0">
        <tr v-for="(item, itemIndex) in items" :key="`tr ${itemIndex}`">
          <td v-for="(column, columnIndex) in tableColumns" :key="`td ${column.text} ${columnIndex}`">
            <template v-if="column.excludeMatching !== true">
              {{ item[column.value] }}
            </template>

            <!-- pricePerUnit, totalPrice -->
            <template v-else-if="column.value === 'pricePerUnit' || column.value === 'totalPrice'">
              {{ numberWithComma(item[column.value]) }}
            </template>
          </td>
        </tr>
        <tr>
          <td colspan="4">Total</td>
          <td>
            {{
              numberWithComma(
                fixedNumber(
                  items.map(i => i.quantity).reduce((partialSum, a) => partialSum + (a || 0), 0),
                  $mathFix,
                  true,
                ),
              )
            }}
          </td>
          <td colspan="8"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { fixedNumber, numberWithComma } from "@/utils/util";
export default {
  props: ["items"],
  data() {
    return {
      tableColumns: [
        //text : thead 표시
        //value : field Name
        //excludeMatching : 테이블 표시시 별도 컨트롤 필요 여부 (false일 경우 value랑 매칭하여 표기), default: false
        { text: "Index", value: "index" },
        { text: "Style#", value: "styleNo" },
        { text: "GarmentColor", value: "garmentColor" },
        { text: "Size", value: "size" },
        { text: "Quantity", value: "quantity" },
        { text: "FullCartonIndicator", value: "fullCartonIndicator" },
        { text: "PrepackType", value: "prepackType" },
        { text: "QtyPerOuterPack", value: "qtyPerOuterPack" },
        { text: "QtyPerInnerPack", value: "qtyPerInnerPack" },
        { text: "PricePerUnit", value: "pricePerUnit", excludeMatching: true },
        { text: "TotalPrice", value: "totalPrice", excludeMatching: true },
        { text: "SKU", value: "sku" },
        { text: "Description", value: "description" },
      ],
    };
  },
  methods: {
    numberWithComma,
    fixedNumber,
  },
};
</script>

<style></style>
