<template>
  <div class="custom-table gt-nexus-single-first">
    <table>
      <thead>
        <tr>
          <th v-for="column in tableColumns" :key="`thead ${column.text}`">
            <span>{{ column.text }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="(column, columnIndex) in tableColumns" :key="`td ${column.text} ${columnIndex}`">
            <template v-if="column.excludeMatching !== true">
              {{ item[column.value] }}
            </template>

            <!-- Qty -->
            <template v-else-if="column.value === 'qty'">
              {{ numberWithComma(item[column.value]) }}
            </template>

            <!-- URL -->
            <span
              v-else-if="column.value === 'url'"
              :class="{ 'font-blue1 pointer hoverUnderline': hasValue(item[column.value]) }"
              @click="urlClicked(item[column.value])">
              Open
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { numberWithComma, hasValue } from "@/utils/util";
export default {
  props: ["item"],
  data() {
    return {
      tableColumns: [
        //text : thead 표시
        //value : field Name
        //excludeMatching : 테이블 표시시 별도 컨트롤 필요 여부 (false일 경우 value랑 매칭하여 표기), default: false
        { text: "ID", value: "id" },
        { text: "BrandName", value: "brandName" },
        { text: "DCPO", value: "dcpo" },
        { text: "IssueDate", value: "issueDate" },
        { text: "CancelAfterDate", value: "cancelAfterDate" },
        { text: "EarliestDate", value: "earliestDate" },
        { text: "LatestDate", value: "latestDate" },
        { text: "ContractShipCancel", value: "contractShipCancel" },
        { text: "InDC", value: "inDC" },
        { text: "InStore", value: "inStore" },
        { text: "Qty", value: "qty", excludeMatching: true },
        { text: "DeptName", value: "deptName" },
        { text: "RetailSeason", value: "retailSeason" },
        { text: "DocumentType", value: "documentType" },
        { text: "AssignedOn", value: "assignedOn" },
        { text: "RevisionNumber", value: "revisionNumber" },
        { text: "URL", value: "url", excludeMatching: true },
      ],
    };
  },
  methods: {
    urlClicked(link) {
      window.open(link, "_blank");
    },
    numberWithComma,
    hasValue,
  },
};
</script>

<style></style>
