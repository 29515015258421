<template>
  <div class="custom-table gt-nexus-style-weekly">
    <table id="gtNexusStyleWeeklyTable">
      <thead>
        <tr>
          <th v-for="column in tableColumns" :key="`thead ${column.text}`">
            <span>{{ column.text }}</span>
          </th>
          <th v-for="sizeKey in sizeKeys" :key="`thead ${sizeKey}`">
            <span> {{ sizeKey }} </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, itemIndex) in items" :key="`tr ${itemIndex}`">
          <td v-for="(column, columnIndex) in tableColumns" :key="`td column ${column.text} ${columnIndex}`">
            <template v-if="column.excludeMatching !== true">
              {{ item[column.value] }}
            </template>

            <span
              v-else-if="groupColumn.includes(column.value)"
              :id="`${column.value}${itemIndex}`"
              :name="`${column.value}`"
              :data-key="getKey(item, column)"
              :data-index="itemIndex"
              v-html="item[column.value]">
            </span>

            <!-- total -->
            <template v-else-if="column.value === 'total'">
              {{ numberWithComma(item[column.value]) }}
            </template>
          </td>

          <td v-for="sizeKey in sizeKeys" :key="`td size ${sizeKey}`">
            <span> {{ numberWithComma(item[sizeKey]) }} </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { numberWithComma, hasValue } from "@/utils/util";
import gtNexusStyleMixin from "@/mixins/gtNexusStyleMixin";

export default {
  props: ["items", "sizeKeys"],
  mixins: [gtNexusStyleMixin],
  data() {
    return {
      tableColumns: [
        //text : thead 표시
        //value : field Name
        //excludeMatching : 테이블 표시시 별도 컨트롤 필요 여부 (false일 경우 value랑 매칭하여 표기), default: false
        { text: "Weekly", value: "weekly", excludeMatching: true, grouping: true },
        { text: "DCPO#", value: "dpoNo", excludeMatching: true, grouping: true },
        { text: "GarmentColor", value: "color" },
        { text: "Total", value: "total", excludeMatching: true },
      ],
    };
  },
  methods: {
    getKey(item, { value }) {
      if (!item || !value) return;

      switch (value) {
        case "weekly":
          return `${item.weekly}`;
        case "dpoNo":
          return `${item.weekly}|${item.dpoNo}`;
        default:
          return "";
      }
    },
    numberWithComma,
    hasValue,
  },
  computed: {
    groupColumn() {
      return this.tableColumns.filter(c => c.grouping).map(c => c.value);
    },
  },
  updated() {
    this.$nextTick(function () {
      this.groupColumn.forEach(column => {
        this.tableGroupping(document.querySelector("#gtNexusStyleWeeklyTable"), column);
      });
    });
  },
};
</script>

<style></style>
