<template>
  <v-dialog
    v-model="dialogShow"
    min-width="400px"
    max-width="1200px"
    content-class="company gt-nexus-group customEditModal">
    <v-card class="pa-5">
      <h1>Group :: {{ groupPoNo }}</h1>

      <div class="content">
        <div v-for="(item, itemIndex) in items" :key="`item ${itemIndex}`" class="mt-10">
          <group-table-first :item="item" />
          <group-table-second :item="item" />
          <group-table-third :items="item.poNoDetailDtoList" class="mt-4" />

          <v-divider v-if="itemIndex !== items.length"></v-divider>
        </div>
      </div>

      <div class="actions d-flex justify-center">
        <v-btn :elevation="0" color="secondary" @click="dialogShow = false">CLOSE</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { hasValue, numberWithComma } from "@/utils/util";
import GroupTableFirst from "./GroupTableFirst.vue";
import GroupTableSecond from "./GroupTableSecond.vue";
import GroupTableThird from "./GroupTableThird.vue";
export default {
  components: { GroupTableFirst, GroupTableSecond, GroupTableThird },
  props: ["show", "items"],
  data() {
    return {};
  },
  methods: {
    hasValue,
    numberWithComma,
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    groupPoNo() {
      return this.items && this.items.length > 0 ? this.items[0].poNo : "";
    },
  },
};
</script>

<style></style>
