<template>
  <div class="gt-nexus">
    <div class="page-info">
      <div class="page-title">
        <p class="title-1">API PO (GT Nexus)</p>
      </div>
    </div>

    <v-card outlined flat rounded="xl" class="pa-8">
      <div class="d-flex mb-4 gutter-mx-3 search-toolbar" style="justify-content: start">
        <!-- Column Select -->
        <div class="column-filter">
          <v-autocomplete dense solo hide-details flat outlined :items="searchColumns" v-model="searchColumn">
            <template v-slot:append>
              <v-icon> mdi-chevron-down </v-icon>
            </template>
          </v-autocomplete>
        </div>

        <!-- Column Search -->
        <div class="search">
          <v-text-field
            dense
            outlined
            hide-details
            v-model="searchText"
            placeholder="Insert Keyword to search"
            background-color="white"
            @keyup.enter="getList(true)"></v-text-field>
        </div>

        <v-btn color="secondary" icon @click="getList(true)" style="margin: 0; align-self: center">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <img
          v-if="activeExcel"
          :src="require('images/ico_xlsx.png')"
          class="align-self-center ml-1 pointer"
          style="width: auto; height: 24px"
          @click="downloadExcel" />
      </div>

      <div class="custom-table">
        <table>
          <thead>
            <tr>
              <th
                v-for="column in tableColumns"
                :key="`thead ${column.text}`"
                :class="{ pointer: column.canSort !== false }"
                @click="column.canSort !== false ? changeSort(column.value) : ''">
                <span>{{ column.text }}</span>

                <i class="fa-solid fa-sort-down" v-if="sort.key === column.value && sort.order === 'desc'"></i>
                <i class="fa-solid fa-sort-up" v-if="sort.key === column.value && sort.order === 'asc'"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, itemIndex) in list">
              <tr :key="`tr ${itemIndex}`">
                <td v-for="(column, columnIndex) in tableColumns" :key="`td ${column.text} ${columnIndex}`">
                  <template v-if="column.excludeMatching !== true">
                    {{ item[column.value] }}
                  </template>

                  <!-- ID -->
                  <span
                    v-else-if="column.value === 'id'"
                    :class="{ 'font-blue1 pointer hoverUnderline': hasValue(item.poSingleSelectDto) }"
                    @click="
                      () => {
                        hasValue(item.poSingleSelectDto) ? idClicked(item.poSingleSelectDto) : () => {};
                      }
                    ">
                    {{ item[column.value] }}
                  </span>

                  <!-- poNo -->
                  <span
                    v-else-if="column.value === 'poNo'"
                    :class="{ 'font-blue1 pointer hoverUnderline': hasValue(item.poNoSelectDtoList) }"
                    @click="
                      () => {
                        hasValue(item.poNoSelectDtoList) ? poNoClicked(item.poNoSelectDtoList) : () => {};
                      }
                    ">
                    {{ item[column.value] }}
                  </span>

                  <!-- dpoNo -->
                  <span
                    v-else-if="column.value === 'dpoNo'"
                    :class="{ 'font-blue1 pointer hoverUnderline': hasValue(item.dpoNoSelectDto) }"
                    @click="
                      () => {
                        hasValue(item.dpoNoSelectDto) ? dpoNoClicked(item.dpoNoSelectDto) : () => {};
                      }
                    ">
                    {{ item[column.value] }}
                  </span>

                  <!-- latest -->
                  <span
                    v-else-if="column.value === 'latest'"
                    :class="{ 'font-error': item[column.value] !== item.cnxl }">
                    {{ item[column.value] }}
                  </span>

                  <!-- cnxl -->
                  <span
                    v-else-if="column.value === 'cnxl'"
                    :class="{ 'font-error': item[column.value] !== item.latest }">
                    {{ item[column.value] }}
                  </span>

                  <!-- styleNo -->
                  <span
                    v-else-if="column.value === 'styleNo'"
                    :class="{ 'font-blue1 pointer hoverUnderline': styleIsActive(item) }"
                    @click="
                      () => {
                        styleIsActive(item) ? styleNoClicked(item.styleNo) : () => {};
                      }
                    ">
                    {{ item[column.value] }}
                  </span>

                  <!-- orderQty -->
                  <template v-else-if="column.value === 'orderQty'">
                    {{ numberWithComma(item[column.value]) }}
                  </template>

                  <!-- url -->
                  <span
                    v-else-if="column.value === 'url'"
                    :class="{ 'font-blue1 pointer hoverUnderline': hasValue(item[column.value]) }"
                    @click="urlClicked(item[column.value])">
                    Open
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div class="text-center pt-2" v-if="pageCount > 0">
        <v-pagination v-model="page" :length="pageCount" color="secondary" :total-visible="$pagePV"></v-pagination>
      </div>
    </v-card>

    <single-modal :show.sync="singleModalShow" :item="singleModalItem" />
    <group-modal :show.sync="groupModalShow" :items="groupModalItems" />
    <single-modal :show.sync="dpoModalShow" :item="dpoModalItem" :isDPO="true" />
    <style-modal :show.sync="styleModalShow" :styleNo="styleModalStyleNo" @changeLoading="changeLoading"></style-modal>

    <v-overlay :value="loading" style="z-index: 1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import codeHandler from "@/utils/codeHandler";
import { hasValue, numberWithComma } from "@/utils/util";
import SingleModal from "./Single/SingleModal.vue";
import GroupModal from "./Group/GroupModal.vue";
import StyleModal from "./Style/StyleModal.vue";
import messageHandler from "@/utils/messageHandler";

export default {
  components: { SingleModal, GroupModal, StyleModal },
  data() {
    return {
      list: [],
      searchColumn: "ALL",
      searchText: "",
      page: 1,
      totalCnt: 0,
      itemsPerPage: 30,
      sort: { key: "id", order: "desc" },
      loading: false,
      columns: [
        //text : thead 표시
        //value : field Name
        //tableShow : table 표시 여부, default: true
        //searchShow : 필터 셀렉트박스 표시 여부, default: true
        //excludeMatching : 테이블 표시시 별도 컨트롤 필요 여부 (false일 경우 value랑 매칭하여 표기), default: false
        //canSort : 정렬 가능 여부, default: true
        { text: "ALL", value: "ALL", tableShow: false },
        { text: "ID", value: "id", excludeMatching: true },
        { text: "Buyer", value: "buyer" },
        { text: "Brand", value: "brand" },
        { text: "PO#", value: "poNo", excludeMatching: true },
        { text: "DPO#", value: "dpoNo", excludeMatching: true },
        { text: "Issue", value: "issue", searchShow: false },
        { text: "Latest", value: "latest", searchShow: false, excludeMatching: true },
        { text: "CNXL", value: "cnxl", searchShow: false, excludeMatching: true },
        { text: "Factory", value: "factory" },
        { text: "InDC", value: "inDC", searchShow: false },
        { text: "InStore", value: "inStore", searchShow: false },
        { text: "OC", value: "oc" },
        { text: "DC", value: "dc" },
        { text: "Style#", value: "styleNo", excludeMatching: true },
        { text: "ProgramType", value: "programType" },
        { text: "OrderQty", value: "orderQty", searchShow: false, excludeMatching: true, canSort: false },
        { text: "DeptName", value: "deptName" },
        { text: "Status", value: "status", canSort: false },
        { text: "DocumentType", value: "documentType" },
        { text: "Modified", value: "modified", searchShow: false },
        { text: "URL", value: "url", searchShow: false, excludeMatching: true, canSort: false },
      ],
      singleModalShow: false,
      singleModalItem: {},
      groupModalShow: false,
      groupModalItems: {},
      dpoModalShow: false,
      dpoModalItem: {},
      styleModalShow: false,
      styleModalStyleNo: null,
      activeExcel: false,
    };
  },
  methods: {
    getList(changeFilters) {
      this.loading = true;
      if (changeFilters) this.page = 1;

      this.$http
        .get("/gt-nexus/buyer-info", {
          data: {
            currentPageNo: this.page,
            filterColumn: this.searchColumn,
            searchText: this.searchText,
            sortColumn: this.sort.key,
            sortType: this.sort.order,
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            //엑셀 다운로드 시, 서버 과부하 문제로 인해 검색 건수를 제한하기 위함.
            this.activeExcel = this.searchText.length >= 3;

            if (Array.isArray(res.data.data)) {
              this.list = res.data.data;
              this.totalCnt = res.data.data[0]?.totalCnt || 0;
            }
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    changeSort(value) {
      if (this.sort.key === value) {
        this.sort.order = this.sort.order === "desc" ? "asc" : "desc";
      } else {
        this.sort.key = value;
        this.sort.order = "desc";
      }
      this.getList(true);
    },
    urlClicked(link) {
      window.open(link, "_blank");
    },
    downloadExcel() {
      this.loading = true;

      this.$http({
        // url: `gt-nexus/buyer-info/excel?currentPageNo=${this.page}&filterColumn=${this.searchColumn}&searchText=${this.searchText}&sortColumn=${this.sort.key}&sortType=${this.sort.order}`,
        url: `gt-nexus/buyer-info/excel?filterColumn=${this.searchColumn}&searchText=${this.searchText}&sortColumn=${this.sort.key}&sortType=${this.sort.order}`,
        method: "GET",
        responseType: "blob",
        timeout: 10 * 60 * 1000, // 10분 제한시간 요청사항
      })
        .then(response => {
          const contentType = response.headers["content-type"];
          if (contentType === "application/json") {
            new Blob([response.data], { type: response.headers["content-type"] })
              .text()
              .then(res => {
                try {
                  const responseJSON = JSON.parse(res);
                  if (responseJSON.status !== "200") codeHandler(responseJSON);
                } catch (err) {
                  messageHandler("error");
                  console.error("excel download, content type is json. blob to text is success, json parse error");
                  console.error(res);
                  console.error(err);
                }
              })
              .catch(err => {
                messageHandler("error");
                console.error("excel download, content type is json, blob to text is failed");
                console.error(err);
              });
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `API_PO.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
        })
        .finally(() => (this.loading = false));
    },
    idClicked(poSingleSelectDto) {
      if (!hasValue(poSingleSelectDto)) return;

      this.singleModalShow = true;
      this.singleModalItem = poSingleSelectDto;
    },
    poNoClicked(poNoSelectDtoList) {
      if (!hasValue(poNoSelectDtoList)) return;

      this.groupModalShow = true;
      this.groupModalItems = poNoSelectDtoList;
    },
    dpoNoClicked(dpoNoSelectDto) {
      if (!hasValue(dpoNoSelectDto)) return;

      this.dpoModalShow = true;
      this.dpoModalItem = dpoNoSelectDto;
    },
    styleIsActive(item) {
      return item.status !== "Issued";
    },
    styleNoClicked(styleNo) {
      if (!hasValue(styleNo)) return;

      this.styleModalShow = true;
      this.styleModalStyleNo = styleNo;
    },
    changeLoading(loading) {
      this.loading = loading;
    },
    hasValue,
    numberWithComma,
  },
  computed: {
    tableColumns() {
      return this.columns.filter(c => c.tableShow !== false);
    },
    searchColumns() {
      return this.columns.filter(c => c.searchShow !== false);
    },
    pageCount() {
      return Math.ceil((this.totalCnt || 0) / this.itemsPerPage);
    },
  },
  watch: {
    page() {
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style></style>
