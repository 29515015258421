<template>
  <div class="custom-table gt-nexus-single-third">
    <div class="d-flex">
      <v-spacer></v-spacer>
      <img
        :src="require('images/ico_xlsx.png')"
        class="align-self-center ml-1 pointer mb-1"
        style="width: auto; height: 24px"
        @click="downloadExcel" />
    </div>

    <table>
      <thead>
        <tr>
          <th v-for="column in tableColumns" :key="`thead ${column.text}`">
            <span>{{ column.text }}</span>
          </th>
        </tr>
      </thead>
      <tbody v-if="items && items.length > 0">
        <tr v-for="(item, itemIndex) in items" :key="`tr ${itemIndex}`">
          <td v-for="(column, columnIndex) in tableColumns" :key="`td ${column.text} ${columnIndex}`">
            <template v-if="column.excludeMatching !== true">
              {{ item[column.value] }}
            </template>

            <!-- pricePerUnit, totalPrice -->
            <template v-else-if="column.value === 'pricePerUnit' || column.value === 'totalPrice'">
              {{ numberWithComma(item[column.value]) }}
            </template>
          </td>
        </tr>
        <tr>
          <td colspan="4">Total</td>
          <td>
            {{
              numberWithComma(
                fixedNumber(
                  items.map(i => i.quantity).reduce((partialSum, a) => partialSum + (a || 0), 0),
                  $mathFix,
                  true,
                ),
              )
            }}
          </td>
          <td colspan="10"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { fixedNumber, numberWithComma } from "@/utils/util";
import codeHandler from "@/utils/codeHandler";
import messageHandler from "@/utils/messageHandler";
export default {
  props: ["items", "id", "dcpo", "isDPO"],
  data() {
    return {
      tableColumns: [
        //text : thead 표시
        //value : field Name
        //excludeMatching : 테이블 표시시 별도 컨트롤 필요 여부 (false일 경우 value랑 매칭하여 표기), default: false
        { text: "Index", value: "index" },
        { text: "Style#", value: "styleNo" },
        { text: "GarmentColor", value: "garmentColor" },
        { text: "Size", value: "size" },
        { text: "Quantity", value: "quantity" },
        { text: "FullCartonIndicator", value: "fullCartonIndicator" },
        { text: "PrepackType", value: "prepackType" },
        { text: "QtyPerOuterPack", value: "qtyPerOuterPack" },
        { text: "QtyPerInnerPack", value: "qtyPerInnerPack" },
        { text: "PricePerUnit", value: "pricePerUnit", excludeMatching: true },
        { text: "TotalPrice", value: "totalPrice", excludeMatching: true },
        { text: "LINE#", value: "line" },
        { text: "Reference", value: "reference" },
        { text: "SKU", value: "sku" },
        { text: "Description", value: "description" },
      ],
    };
  },
  methods: {
    downloadExcel() {
      this.$http({
        url: this.isDPO ? `gt-nexus/dcpo/excel?id=${this.id}&dcpo=${this.dcpo}` : `gt-nexus/single/excel?id=${this.id}`,
        method: "GET",
        responseType: "blob",
      }).then(response => {
        const contentType = response.headers["content-type"];
        if (contentType === "application/json") {
          new Blob([response.data], { type: response.headers["content-type"] })
            .text()
            .then(res => {
              try {
                const responseJSON = JSON.parse(res);
                if (responseJSON.status !== "200") codeHandler(responseJSON);
              } catch (err) {
                messageHandler("error");
                console.error("excel download, content type is json. blob to text is success, json parse error");
                console.error(res);
                console.error(err);
              }
            })
            .catch(err => {
              messageHandler("error");
              console.error("excel download, content type is json, blob to text is failed");
              console.error(err);
            });
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `PO_${this.id}_View.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    numberWithComma,
    fixedNumber,
  },
};
</script>

<style></style>
