export default {
  methods: {
    tableGroupping(root, column) {
      // 정렬이 보장되어야 함. 정렬이 꼬여있다면 정상적인 그룹핑이 되지 않음.
      const items = Array.from(root.querySelectorAll(`[name=${column}]`));
      const length = items.length;
      const itemsInfo = {};

      items.forEach(m => {
        const { key } = m.dataset;
        const index = parseInt(m.dataset.index);

        if (!itemsInfo[key]) {
          itemsInfo[key] = {};
          itemsInfo[key].min = length;
          itemsInfo[key].length = 0;
        }

        if (itemsInfo[key].min > index) itemsInfo[key].min = index;
        itemsInfo[key].length++;
      });

      Object.keys(itemsInfo).forEach(key => {
        const { min, length } = itemsInfo[key];
        root.querySelector(`#${column}${min}`).parentElement.rowSpan = length;

        const max = min + length;
        for (let i = min + 1; i < max; i++) {
          const td = root.querySelector(`#${column}${i}`);
          td.parentElement.remove();
        }
      });
    },
  },
};
